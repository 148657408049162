import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import GraphQLExample from '../../../components/GraphQLExample';
import example from '../../../examples/createSignatureOrder/webhook';
export const _frontmatter = {
  "product": "signatures",
  "category": "Webhooks",
  "sort": 0,
  "title": "Configuration"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`Webhooks`}</strong>{` are configured on a per-SignatureOrder basis.`}</p>
    <p>{`You simply provide your webhook uri as part of creating a signature order (`}<inlineCode parentName="p">{`createSignatureOrder`}</inlineCode>{` mutation) by adding it as an input variable `}<inlineCode parentName="p">{`webhook.uri`}</inlineCode>{` as shown below.`}</p>
    <h2>{`Example`}</h2>

    <GraphQLExample example={example} mdxType="GraphQLExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      